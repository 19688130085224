import './App.scss';
import { CSSTransition } from 'react-transition-group';
import CanvasEntrace from './components/CanvasEntrace/CanvasEntrace';
import { useRef, useEffect, useState } from 'react';
import { useDetectGPU, useProgress } from '@react-three/drei';
import FixedButtonGroup from './components/FixedButtonGroup/FixedButtonGroup';
import LoadingPage from './components/LoadingPage/LoadingPage';

const App = () => {
    const { isMobile } = useDetectGPU();
    const sceneOneVideoRefPhone = useRef<HTMLVideoElement>(null);
    const sceneOneVideoRefPC = useRef<HTMLVideoElement>(null);

    const [loaderStatus, setLoaderStatus] = useState<boolean>(true);

    const [canvasStatus, setCanvasStatus] = useState<boolean>(false);
    const [videoStatus, setVideoStatus] = useState<boolean>(true);
    const [hallwayToLoby, setHallwayToLoby] = useState<any>(null);
    const [hallwayPositionX, setHallwayPositionX] = useState<number>(0);

    const { loaded } = useProgress();
    const loadingCount = isMobile ? 55 : 64;

    useEffect(() => {
        if (sceneOneVideoRefPhone.current) {
            sceneOneVideoRefPhone.current.onended = (event) => {
                (event.target as HTMLVideoElement).currentTime = 0;
                (event.target as HTMLVideoElement).play();
            };
        }

        if (sceneOneVideoRefPC.current) {
            sceneOneVideoRefPC.current.onended = (event) => {
                (event.target as HTMLVideoElement).currentTime = 0;
                (event.target as HTMLVideoElement).play();
            };
        }
    }, [videoStatus]);

    useEffect(() => {
        if (canvasStatus) {
            setTimeout(() => {
                setVideoStatus(false);
            }, 5000);
        } else setVideoStatus(true);
    }, [canvasStatus]);

    return (
        <div
            className="app"
            style={{ height: '100%' }}
        >
            <CSSTransition
                in={loaderStatus}
                timeout={300}
                classNames="fade-video"
            >
                <div className="loading-container">
                    {loaderStatus && <LoadingPage setLoaderStatus={setLoaderStatus} />}
                </div>
            </CSSTransition>

            {canvasStatus && (
                <CanvasEntrace
                    hallwayPositionX={hallwayPositionX}
                    setCanvasStatus={setCanvasStatus}
                    setHallwayToLoby={setHallwayToLoby}
                />
            )}

            <CSSTransition
                in={videoStatus}
                timeout={300}
                classNames="fade-video"
            >
                <div className="video-wrap">
                    <video
                        ref={sceneOneVideoRefPhone}
                        className="cover-video cover-video-phone"
                        muted
                        autoPlay
                        playsInline
                        src="/leading/leadingPageAssets/indexPage/bg_video_m.mp4"
                    />
                    <video
                        ref={sceneOneVideoRefPC}
                        className="cover-video cover-video-pc"
                        muted
                        autoPlay
                        playsInline
                        src="/leading/leadingPageAssets/indexPage/bg_video.mp4"
                    />
                </div>
            </CSSTransition>

            <CSSTransition
                in={canvasStatus && videoStatus && !loaderStatus}
                timeout={300}
                classNames="fade-loading-wrap"
            >
                <div className="loading-wrap">
                    <img
                        className="loading-effect"
                        src="/leading/leadingPageAssets/indexPage/loading_effect.gif"
                        alt="goBetter"
                    />

                    <div className="loading-hint">
                        <img
                            className="spin-circle"
                            src="/leading/leadingPageAssets/indexPage/dots_animation.png"
                            alt="goBetter"
                        />

                        <div className="text">
                            {`${((loaded / loadingCount) * 100).toFixed(0)}%`}
                        </div>
                    </div>
                </div>
            </CSSTransition>

            <FixedButtonGroup
                canvasStatus={canvasStatus && !loaderStatus}
                setCanvasStatus={setCanvasStatus}
                setHallwayPositionX={setHallwayPositionX}
                hallwayToLoby={hallwayToLoby}
            />
        </div>
    );
};

export default App;
