import { useDetectGPU, Circle, Torus, Capsule } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useRef, memo, useMemo } from 'react';
import { Clock } from 'three';
import { makeSineWaveLoop } from 'utils/makeSineWaveLoop';
import { useMainContext } from 'hooks/useMainContext';
import * as THREE from 'three';

const NoticePoint = ({
    title,
    position,
    noticePointLineHeight,
    noticePointLinePosition,
    handleClick,
    handlePointerMove,
    handlePointerLeave
}: any) => {
    const { center } = useMainContext();
    const { isMobile } = useDetectGPU();
    const circleRef = useRef<any>();
    const circleRef2 = useRef<any>();
    const circleRef3 = useRef<any>();

    const opacityOrigine = new THREE.Vector2(1, 0);
    const opacityTarget = new THREE.Vector2(0, 0);

    const gray = useMemo(() => {
        return new THREE.Color(0xb2b2b4);
    }, []);
    const orange = useMemo(() => {
        return new THREE.Color(0xef8e35);
    }, []);
    const green = useMemo(() => {
        return new THREE.Color(0x4cd8c0);
    }, []);

    const handleTorus = (clock: Clock) => {
        if (isMobile) return;

        if (center.hallwayfogStatus === 0) {
            circleRef.current.material.color = gray;
            circleRef2.current.material.color = gray;
        }
        if (center.hallwayfogStatus === 1) {
            circleRef.current.material.color = orange;
            circleRef2.current.material.color = orange;
        }
        if (center.hallwayfogStatus === 2) {
            circleRef.current.material.color = green;
            circleRef2.current.material.color = green;
        }

        if (center.hallwayText.type !== title) {
            opacityOrigine.set(1, 0);

            circleRef.current.material.opacity = 0;
            circleRef2.current.material.opacity = 0;
            circleRef3.current.material.opacity = 0;
            return;
        }

        if (
            center.hallwayText.type === title &&
            center.hallwayText.opacity === 1
        ) {
            opacityOrigine.set(1, 0);
            circleRef.current.material.opacity = 1;
            circleRef3.current.material.opacity = 1;

            const { loop, percent, count } = makeSineWaveLoop(clock, 0.75);

            if (count % 4 === 0 || count % 4 === 1) return;

            const scaleParameter = 1.5 * loop + 1;

            circleRef2.current.scale.set(
                scaleParameter,
                scaleParameter,
                scaleParameter
            );

            if (percent > 0.5) {
                return;
            }
            circleRef2.current.material.opacity = 1 * (1 - loop);
        }

        if (
            center.hallwayText.type === title &&
            center.hallwayText.opacity === 0
        ) {
            opacityTarget.set(0, 0);
            opacityOrigine.lerp(opacityTarget, 0.08);

            circleRef.current.material.opacity = opacityOrigine.x;
            circleRef2.current.material.opacity = 0;
            circleRef3.current.material.opacity = opacityOrigine.x;
        }
    };
    useFrame(({ clock }) => {
        handleTorus(clock);
    });
    return (
        <group
            position={position}
            layers={1}
            onClick={handleClick}
            onPointerLeave={handlePointerLeave}
            onPointerMove={handlePointerMove}
        >
            <Circle
                ref={circleRef as any}
                args={[0.1, 32]}
                position={[0, 3.08, -6.73]}
                layers={1}
            >
                <meshBasicMaterial
                    color={'#000000'}
                    // opacity={0.75}
                    transparent={true}
                />
            </Circle>

            <Torus
                ref={circleRef2}
                args={[0.1, 0.01]}
                position={[0, 3.08, -6.68]}
                scale={[1, 1, 1]}
                layers={1}
            >
                <meshBasicMaterial
                    transparent={true}
                    color={'#000000'}
                />
            </Torus>

            <Capsule
                ref={circleRef3}
                args={[0.003, noticePointLineHeight]}
                position={noticePointLinePosition}
                scale={[1, 1, 1]}
                layers={1}
            >
                <meshBasicMaterial
                    transparent={true}
                    color={'#000000'}
                />
            </Capsule>
        </group>
    );
};

export default memo(NoticePoint);
