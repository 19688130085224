import { Camera } from '@react-three/fiber';
import {
    CAMERA_ROTATION_MOVEMENT_Y,
    START_X,
    START_Y,
    START_Z,
    SCENE_1_INITIAL_X,
    SCENE_1_INITIAL_Y,
    SCENE_1_INITIAL_Z,
    SCENE_1_TARGET_Z,
    SCENE_1_TARGET_Y
} from './cameraConfig';
import { Vector2, Vector3 } from 'three';

type Scene = 'loby' | 'hallway';

interface CameraRotation {
    x: number;
    y: number;
    z: number;
}

interface HallwayTextTypes {
    type: string;
    opacity: number;
    color: string;
}
export class CamMovingCenter {
    camera: Camera;
    isMoving: boolean;
    where: 'loby' | 'hallway' | 'start';
    cameraPosition: Vector3;
    cameraPositionSmooth: Vector3;
    brightness: number;
    isFogOpen: boolean;
    hallwayfogStatus: number;
    hallwayText: HallwayTextTypes;
    hallwayPercentOrgine: Vector2;
    hallwayPercentTarget: Vector2;
    // cameraRotation: CameraRotation;

    defaultCameraRotation: CameraRotation;
    cameraRotationOffset: CameraRotation;
    lobyScrollPercent: number;
    scrollPause: boolean;

    constructor(camera: Camera, isMobile?: boolean) {
        this.camera = camera;
        this.isMoving = false;
        this.isFogOpen = false;
        this.where = 'loby';
        this.brightness = 0;
        this.cameraPosition = isMobile
            ? new Vector3(START_X, START_Y, 52)
            : new Vector3(START_X, START_Y, START_Z);
        this.cameraPositionSmooth = new Vector3(
            SCENE_1_INITIAL_X,
            SCENE_1_INITIAL_Y,
            SCENE_1_INITIAL_Z
        );
        this.hallwayfogStatus = 0;
        this.hallwayText = {
            type: '',
            opacity: 0,
            color: '#e1deec'
        };
        this.hallwayPercentOrgine = new Vector2(0, 0);
        this.hallwayPercentTarget = new Vector2(0, 0);
        this.lobyScrollPercent = 0;
        this.scrollPause = false;

        // this.cameraRotation = { x: -4.5, y: 1.5, z: 0 };

        this.defaultCameraRotation = isMobile
            ? {
                x: 5,
                y: CAMERA_ROTATION_MOVEMENT_Y / 2, //百分比才會在中間
                z: 0
            }
            : {
                x: 0,
                y: CAMERA_ROTATION_MOVEMENT_Y / 2, //百分比才會在中間
                z: 0
            };
        this.cameraRotationOffset = { x: 0, y: 0, z: 0 };
    }

    handleChangePosition(scene: Scene) {
        this.where = scene;
    }
    // 是否正在移動
    handleMoving(status: boolean) {
        this.isMoving = status;
    }

    handleCamPositionZMoveCalculate = (direction: boolean) => {
        const scrollOffsets = 0.1;
        if (direction) {
            // 往後
            if (this.lobyScrollPercent <= 0) {
                this.lobyScrollPercent = 0;
                return;
            }
            this.lobyScrollPercent -= scrollOffsets;
        } else {
            // 往前
            if (this.lobyScrollPercent + scrollOffsets >= 1) {
                this.lobyScrollPercent = 1;
                return;
            }
            this.lobyScrollPercent += scrollOffsets;
        }
    };

    // Loby Scroll Event
    handleCameraPositionZMove(direction: boolean) {
        if (this.where !== 'loby') return;
        if (this.isMoving) return;

        this.handleCamPositionZMoveCalculate(direction);

        this.cameraPositionSmooth.z =
            SCENE_1_INITIAL_Z -
            (SCENE_1_INITIAL_Z - SCENE_1_TARGET_Z) * this.lobyScrollPercent;

        this.cameraPositionSmooth.y =
            SCENE_1_INITIAL_Y -
            (SCENE_1_INITIAL_Y - SCENE_1_TARGET_Y) * this.lobyScrollPercent;

        const defaultRotationX = 0 + 5 * this.lobyScrollPercent;
        this.defaultCameraRotation.x = defaultRotationX;
    }

    // Hallway Scroll Event
    handleHallwayCamMove(direction: boolean) {
        if (this.where !== 'hallway') return;
        if (this.isMoving) return;

        direction
            ? (this.hallwayPercentTarget.x -= 0.01)
            : (this.hallwayPercentTarget.x += 0.01);
    }
}
