import BtmGround from '../BtmGround/BtmGround';
import { INFINITY_ITEM_DEPTH } from 'features/CamMovingCenter/cameraConfig';
import { memo } from 'react';

const BtmGroundSet = () => {
    return (
        <>
            <BtmGround position={[0, 0, 0]} />
            <BtmGround position={[0, 0, -INFINITY_ITEM_DEPTH]} />
            <BtmGround position={[0, 0, -2 * INFINITY_ITEM_DEPTH]} />
            <BtmGround position={[0, 0, -3 * INFINITY_ITEM_DEPTH]} />
        </>
    );
};

export default memo(BtmGroundSet);
