import { makeBuildingCircleLoop } from '../utils/makeBuildingCircleLoop';
import {
    INFINITY_START_POSITION,
    INFINITY_GROUP_DEPTH,
    INFINITY_ITEM_DEPTH
} from '../cameraConfig';
import { CamMovingCenter } from '../CamMovingCenter';
import { useDetectGPU } from '@react-three/drei';
import { useEffect } from 'react';
import { Vector2 } from 'three';
import { throttle } from 'lodash';

const useMouseScrollAtBottom = (center: CamMovingCenter) => {
    const { isMobile } = useDetectGPU();

    const handleMouseScroll = throttle((e: any) => {
        if (center.scrollPause) return;
        if (isMobile) return;
        if (e.deltaY > 0) {
            center.handleHallwayCamMove(false);
        }

        if (e.deltaY < 0) {
            center.handleHallwayCamMove(true);
        }
    }, 50);

    useEffect(() => {
        window.addEventListener('wheel', handleMouseScroll);
        return () => {
            window.removeEventListener('wheel', handleMouseScroll);
        };
    }, []);

    const moveBuilding = (percentage: Vector2, r1: any, r2: any, r3: any) => {
        const r1p = makeBuildingCircleLoop(
            percentage.x,
            INFINITY_START_POSITION
        );
        const r2p = makeBuildingCircleLoop(
            percentage.x,
            INFINITY_START_POSITION - INFINITY_ITEM_DEPTH
        );
        const r3p = makeBuildingCircleLoop(
            percentage.x,
            INFINITY_START_POSITION + INFINITY_GROUP_DEPTH + 41
        );

        if (!isMobile) {
            if (r2p > 40 && r2p < 204) {
                center.hallwayfogStatus = 1;
            }
            if (r3p > 40 && r3p < 255) {
                center.hallwayfogStatus = 2;
            }
            if (r1p > 40 && r1p < 81.2) {
                center.hallwayfogStatus = 0;
            }
        }

        r1.current.position.set(0, -1, r1p);
        r2.current.position.set(0, -1, r2p);
        r3.current.position.set(0, -1, r3p);
    };

    return {
        scrollMoving: (r1: any, r2: any, r3: any) => {
            center.hallwayPercentOrgine.lerp(center.hallwayPercentTarget, 0.03);
            moveBuilding(center.hallwayPercentOrgine, r1, r2, r3);
        }
    };
};

export default useMouseScrollAtBottom;
