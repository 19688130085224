import { Clock } from 'three';
import { turnToDegrees } from './turnToDegrees';

// 正弦波 ＋ 絕對值 附帶百分比（0~1~0的百分比）
export const makeSineWaveLoop = (clock: Clock, second: number) => {
    const loop = Math.abs(
        Math.sin(turnToDegrees((clock.elapsedTime * 180) / second))
    ); // 0~1~0

    const percent = (clock.elapsedTime % second) / second; //跑完0~1~0的百分比
    const count = parseInt((clock.elapsedTime / second).toString()); //跑幾次百分比的數量

    return { count, loop, percent };
};
