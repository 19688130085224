import { CamMovingCenter } from '../CamMovingCenter';
import { useEffect } from 'react';
import { useDetectGPU } from '@react-three/drei';
import { SCENE_1_TARGET_Z, SCENE_1_INITIAL_Z } from '../cameraConfig';
import { throttle } from 'lodash';

const useMouseScroll = (center: CamMovingCenter) => {
    const { isMobile } = useDetectGPU();

    const handleMouseScroll = throttle((e: any) => {
        if (isMobile) return;
        if (e.deltaY > 0) {
            if (center.cameraPositionSmooth.z <= SCENE_1_TARGET_Z) {
                return;
            }
            if (center.scrollPause) return;
            center.handleCameraPositionZMove(false);
        }

        if (e.deltaY < 0) {
            if (center.cameraPositionSmooth.z >= SCENE_1_INITIAL_Z) {
                return;
            }
            if (center.scrollPause) return;
            center.handleCameraPositionZMove(true);
        }
    }, 50);

    useEffect(() => {
        window.addEventListener('wheel', (e: any) => {
            handleMouseScroll(e);
        });
        return () => {
            window.removeEventListener('wheel', (e: any) => {
                handleMouseScroll(e);
            });
        };
    }, []);

    return {};
};

export default useMouseScroll;
