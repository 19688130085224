import { memo, useEffect } from 'react';
import Bottoms from 'components/models/Bottoms/Bottoms';
import SceneOne from './SceneOne';
import { useMainContext } from 'hooks/useMainContext';

const SceneEntrace = ({lobyToHallway, hallwayPositionX}: any) => {
    const { center } = useMainContext();

    useEffect(() => {
        lobyToHallway();

        center.hallwayPercentOrgine.x = hallwayPositionX;
        center.hallwayPercentTarget.x = hallwayPositionX;
    }, []);

    return (
        <>
            <SceneOne />

            {/* layer 2 */}
            <Bottoms />
        </>
    );
};

export default memo(SceneEntrace);
