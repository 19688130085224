import BtmGround from 'components/models/BtmGround/BtmGround';
import useHallwayThree from './useHallwayThree';
import HallwayThreePc from './HallwayThreePc/HallwayThreePc';
import { memo } from 'react';
const HallwayThree = ({ groupRef }: any) => {
    const { ARR_BOTTOM_GROUND } = useHallwayThree();

    return (
        <group ref={groupRef as any}>
            <HallwayThreePc />
            {ARR_BOTTOM_GROUND.map((item, index) => {
                return (
                    <BtmGround
                        key={index}
                        position={item}
                    />
                );
            })}
        </group>
    );
};

export default memo(HallwayThree);
