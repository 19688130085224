import { memo, useEffect, createContext } from 'react';
import SceneEntrace from '../SceneEntrace/SceneEntrace';
import CanvasSetting from '../CanvasSetting/CanvasSetting';
import useMoveGroup from '../../../features/CamMovingCenter/hook/useMoveGroup';
import useCamMoveCenter from '../../../features/CamMovingCenter/hook/useCamMoveCenter';
import '../../../styles/uiBtn.scss';

export const MainContext: any = createContext(null);

const CanvasContainer = ({
    hallwayPositionX,
    setCanvasStatus,
    setHallwayToLoby
}: any) => {
    const camMoveCenter = useCamMoveCenter();

    const { lobyToHallway, hallwayToLoby } = useMoveGroup(camMoveCenter.center);

    useEffect(() => {
        setHallwayToLoby(() => () => {
            hallwayToLoby();

            setTimeout(() => {
                setCanvasStatus(false);
            }, 3000);
        });
    });

    return (
        <MainContext.Provider value={{ ...camMoveCenter }}>
            <CanvasSetting />
            <SceneEntrace
                lobyToHallway={lobyToHallway}
                hallwayPositionX={hallwayPositionX}
            />
        </MainContext.Provider>
    );
};

export default memo(CanvasContainer);
