import {
    // Stats,
    AdaptiveEvents,
    Environment,
    // OrbitControls,
    Preload,
    useEnvironment
} from '@react-three/drei';
import LighterModel from '../../models/LighterModel/LighterModel';
import Cam from '../../models/Cam/Cam';
// import { useControls } from 'leva';
import { memo, useRef } from 'react';
import {
    EffectComposer,
    BrightnessContrast,
    SMAA
} from '@react-three/postprocessing';
import { useFrame } from '@react-three/fiber';
import { useMainContext } from 'hooks/useMainContext';

const CanvasSetting = () => {
    const { center } = useMainContext();
    const brightnessRef = useRef<any>();
    const envMap = useEnvironment({ files: '/leading/leadingPageAssets/skyBox/midday.hdr' });
    // const { OrbitControlss } = useControls({
    //     OrbitControlss: { value: true }
    // });

    useFrame(() => {
        if (!brightnessRef) return;
        brightnessRef.current.brightness = center.brightness;
    });
    return (
        <>
            {/* {OrbitControlss ? <Cam /> : <OrbitControls />} */}
            {/* <OrbitControls /> */}
            {/* <Stats showPanel={0} /> */}
            <Cam />
            <Preload all />
            <Environment map={envMap} />
            <LighterModel />
            <AdaptiveEvents />

            <EffectComposer>
                <BrightnessContrast
                    ref={brightnessRef}
                    brightness={0}
                />
                <SMAA />
            </EffectComposer>
        </>
    );
};

export default memo(CanvasSetting);
