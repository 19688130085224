import { memo, useState, useRef } from 'react';
import { Plane, MeshDistortMaterial, Text } from '@react-three/drei';
import * as THREE from 'three';
import HallwayVideoDialog from '../HallwayVideoDialog/HallwayVideoDialog';
import { useMainContext } from 'hooks/useMainContext';
import useHallwayVideoPlane from './useHallwayVideoPlane';
import NoticePoint from '../NoticePoint/NoticePoint';

export interface DialogContent {
    title: string;
    subtitle: string;
    type: 'image' | 'youtube';
    textureType?: 'image' | 'video';
    src: string;
    texture?: any;
    textOneXOffset: number;
    textOneYOffset?: number;
    textTwoXOffset: number;
    textTwoYOffset?: number;
}

export interface HallwayVideoPlaneProps {
    children: any;
    textureType?: string;
    args: number[];
    position: number[];
    noticePointPosition?: number[];
    noticePointLinePosition?: number[];
    noticePointLineHeight?: number;
    rotation?: number[];
    dialogContent: DialogContent;
}

const HallwayVideoPlane = ({
    dialogContent,
    children,
    args,
    position,
    noticePointPosition,
    noticePointLinePosition,
    noticePointLineHeight,
    rotation
}: HallwayVideoPlaneProps) => {
    const {
        title,
        subtitle,
        type,
        // textureType = 'image',
        src,
        texture
    } = dialogContent;

    const ref = useRef<any>();
    const textRef = useRef<any>();
    const textRef2 = useRef<any>();

    const { center } = useMainContext();
    const [contentStatus, setContentStatus] = useState(false);

    const {
        textArr,
        dialogStatus,
        setDialogStatus,
        handleClick,
        handlePointerMove,
        handlePointerLeave
    } = useHallwayVideoPlane(
        dialogContent,
        center,
        ref,
        textRef,
        textRef2,
        setContentStatus,
        position
    );

    return (
        <>
            {noticePointPosition && (
                <NoticePoint
                    title={title}
                    position={noticePointPosition}
                    noticePointLineHeight={noticePointLineHeight}
                    noticePointLinePosition={noticePointLinePosition}
                    handleClick={handleClick}
                    handlePointerMove={handlePointerMove}
                    handlePointerLeave={handlePointerLeave}
                />
            )}

            <Plane
                ref={ref as any}
                args={args as any}
                position={position as any}
                layers={1}
                rotation={rotation as any}
                onClick={handleClick}
                onPointerMove={handlePointerMove}
                onPointerLeave={handlePointerLeave}
            >
                <MeshDistortMaterial
                    map={texture as any}
                    side={THREE.FrontSide}
                    transparent={true}
                    speed={1.8}
                    distort={0.27}
                    specularIntensity={0}
                    envMapIntensity={-0.7}
                />
            </Plane>

            {textArr.map((item: any, index) => {
                return (
                    <Text
                        key={index}
                        {...item}
                    >
                    </Text>
                );
            })}

            <HallwayVideoDialog
                title={title}
                subtitle={subtitle}
                src={src}
                type={type}
                dialogStatus={dialogStatus}
                setDialogStatus={setDialogStatus}
                contentStatus={contentStatus}
                setContentStatus={setContentStatus}
            >
                {children}
            </HallwayVideoDialog>
        </>
    );
};

export default memo(HallwayVideoPlane);
