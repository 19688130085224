import { useMainContext } from 'hooks/useMainContext';
import { useFrame } from '@react-three/fiber';
import { useRef, memo } from 'react';
import * as THREE from 'three';
import useMouseScrollAtBottom from 'features/CamMovingCenter/hook/useMouseScrollAtBottom';
import { Plane, useDetectGPU } from '@react-three/drei';
import HallwayOne from './HallwayOne/HallwayOne';
import HallwayTwo from './HallwayTwo/HallwayTwo';
import HallwayThree from './HallwayThree/HallwayThree';
import Guide from './Guide/Guide';
import { preventCanvasBubbling } from 'utils/preventCanvasBubbling';
import MobileCarousel from './MobileCarousel/MobileCarousel';
import ScrollNotice from '../../FixedButtonGroup/components/ScrollNotice';

const Bottoms = () => {
    const btmOne = useRef<THREE.Group>();
    const btmTwo = useRef<THREE.Group>();
    const btmThree = useRef<THREE.Group>();

    const { isMobile } = useDetectGPU();
    const { center } = useMainContext();
    const { scrollMoving } = useMouseScrollAtBottom(center);

    useFrame(() => {
        center.where === 'hallway' && scrollMoving(btmOne, btmTwo, btmThree);
    });

    return (
        <>
            <Guide />

            {!isMobile && <ScrollNotice />}

            <group>
                {/* 迷霧牆 */}
                <Plane
                    args={[800, 1200]}
                    position={[0, 0, -50]}
                    layers={1}
                    onPointerEnter={preventCanvasBubbling}
                    onClick={preventCanvasBubbling}
                >
                    <meshBasicMaterial color={'#dfe9f3'} />
                </Plane>

                {/* 攝影機位置 */}
                {/* DEV */}
                {/* <Plane
                    args={[10, 10]}
                    position={[0, -81, 10.2]}
                    layers={1}
                >
                    <meshBasicMaterial
                        color={'#2b8bf8'}
                        side={THREE.DoubleSide}
                    />
                </Plane> */}

                <MobileCarousel />

                <HallwayOne groupRef={btmOne} />
                <HallwayTwo groupRef={btmTwo} />
                <HallwayThree groupRef={btmThree} />
            </group>
        </>
    );
};

export default memo(Bottoms);
