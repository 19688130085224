import useDracoLoader from 'hooks/useDracoLoader';
import BasicModel from '../BasicModel/BasicModel';
import { Vector3 } from '@react-three/fiber';
import { memo } from 'react';
import { useMainContext } from 'hooks/useMainContext';
import { Vector2 } from 'three';

interface BottomArtModelTwoProps {
    position: number[];
}

const BottomArtModelTwo = ({ position }: BottomArtModelTwoProps) => {
    const gltf = useDracoLoader(
        '/leading/leadingPageAssets/models/Bottom/Abstract02/Abstract02.gltf'
    );

    const { textures } = useMainContext();
    const {
        bottomArtModelTwomap,
        bottomArtModelTwometalnessMap,
        bottomArtModelTwonormalMap
    } = textures;

    return (
        <group
            position={position as Vector3}
            layers={1}
        >
            {gltf.scene.children.map((item: any, index: any) => {
                if (item.name === 'Torus009') {
                    return (
                        <BasicModel
                            key={index}
                            item={item}
                            maps={{
                                map: bottomArtModelTwomap,
                                metalnessMap: bottomArtModelTwometalnessMap,
                                lightMap: bottomArtModelTwometalnessMap,
                                normalMap: bottomArtModelTwonormalMap,
                                reflectivity: 0.1,
                                roughness: 0.4,
                                metalness: 0.5,
                                normalScale: new Vector2(-100, -200),
                                envMapIntensity: 0
                            }}
                            layers={1}
                        />
                    );
                }
                return (
                    <BasicModel
                        key={index}
                        item={item}
                        maps={{
                            reflectivity: 0.46,
                            roughness: 0.61,
                            metalness: 1,
                            color: '#D1D1D1'
                        }}
                        layers={1}
                    />
                );
            })}
        </group>
    );
};

export default memo(BottomArtModelTwo);
