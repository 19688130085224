import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

const useContactDialog = () => {
    const { t, i18n } = useTranslation();
    const PRODUCT = useMemo(
        () => [
            { name: t('All'), value: t('All') },
            { name: t('LiveCasino'), value: t('LiveCasino') },
            { name: t('Casino'), value: t('Casino') },
            { name: t('Lottery'), value: t('Lottery') },
            { name: t('Sports'), value: t('Sports') },
            { name: t('CardGame'), value: t('CardGame') },
            { name: t('Mobile'), value: t('Mobile') }
        ],
        [i18n.language, t]
    );

    const APP = useMemo(
        () => [
            { name: 'Skype', value: 'Skype' },
            { name: 'QQ', value: 'QQ' },
            { name: 'Line', value: 'Line' },
            { name: 'WeChat', value: 'WeChat' },
            { name: 'Others', value: 'Others' }
        ],
        []
    );

    const SUBJECT = useMemo(
        () => [
            { name: t('GameAPI'), value: t('GameAPI') },
            { name: t('WhiteLabelSolution'), value: t('WhiteLabelSolution') },
            { name: t('Others'), value: t('Others') }
        ],
        [i18n.language, t]
    );

    // 國家 ＋ 國碼
    const COUNTRY = [
        {
            name: t('Taiwan'),
            value: t('Taiwan'),
            code: '886'
        },
        {
            name: t('USA'),
            value: t('USA'),
            code: '1'
        },
        {
            name: t('MainlandChina'),
            value: t('MainlandChina'),
            code: '86'
        },
        {
            name: t('Argentina'),
            value: t('Argentina'),
            code: '54'
        },
        {
            name: t('Belize'),
            value: t('Belize'),
            code: '501'
        },
        {
            name: t('Brazil'),
            value: t('Brazil'),
            code: '55'
        },
        {
            name: t('Bolivia'),
            value: t('Bolivia'),
            code: '591'
        },
        {
            name: t('Chile'),
            value: t('Chile'),
            code: '56'
        },
        {
            name: t('ElSalvador'),
            value: t('ElSalvador'),
            code: '503'
        },
        {
            name: t('Panama'),
            value: t('Panama'),
            code: '507'
        },
        {
            name: t('Venezuela'),
            value: t('Venezuela'),
            code: '58'
        },
        {
            name: t('Bahrain'),
            value: t('Bahrain'),
            code: '973'
        },
        {
            name: t('Bangladesh'),
            value: t('Bangladesh'),
            code: '880'
        },
        {
            name: t('Brunei'),
            value: t('Brunei'),
            code: '673'
        },
        {
            name: t('Cambodia'),
            value: t('Cambodia'),
            code: '855'
        },
        {
            name: t('Guam'),
            value: t('Guam'),
            code: '1671'
        },
        {
            name: t('HongKong'),
            value: t('HongKong'),
            code: '852'
        },
        {
            name: t('India'),
            value: t('India'),
            code: '91'
        },
        {
            name: t('Indonesia'),
            value: t('Indonesia'),
            code: '62'
        },
        {
            name: t('Israel'),
            value: t('Israel'),
            code: '972'
        },
        {
            name: t('Japan'),
            value: t('Japan'),
            code: '81'
        },
        {
            name: t('Jordan'),
            value: t('Jordan'),
            code: '962'
        },
        {
            name: t('SouthKorea'),
            value: t('SouthKorea'),
            code: '82'
        },
        {
            name: t('Kuwait'),
            value: t('Kuwait'),
            code: '965'
        },
        {
            name: t('Macau'),
            value: t('Macau'),
            code: '853'
        },
        {
            name: t('Malaysia'),
            value: t('Malaysia'),
            code: '60'
        },
        {
            name: t('Maldives'),
            value: t('Maldives'),
            code: '960'
        },
        {
            name: t('Mongolia'),
            value: t('Mongolia'),
            code: '976'
        },
        {
            name: t('Oman'),
            value: t('Oman'),
            code: '968'
        },
        {
            name: t('Pakistan'),
            value: t('Pakistan'),
            code: '92'
        },
        {
            name: t('Philippines'),
            value: t('Philippines'),
            code: '63'
        },
        {
            name: t('SaudiArabia'),
            value: t('SaudiArabia'),
            code: '966'
        },
        {
            name: t('Singapore'),
            value: t('Singapore'),
            code: '65'
        },
        {
            name: t('SriLanka'),
            value: t('SriLanka'),
            code: '94'
        },
        {
            name: t('Syria'),
            value: t('Syria'),
            code: '963'
        },
        {
            name: t('Thailand'),
            value: t('Thailand'),
            code: '66'
        },
        {
            name: t('Turkey'),
            value: t('Turkey'),
            code: '90'
        },
        {
            name: t('U.A.E.'),
            value: t('U.A.E.'),
            code: '971'
        },
        {
            name: t('Vietnam'),
            value: t('Vietnam'),
            code: '84'
        },
        {
            name: t('Australia'),
            value: t('Australia'),
            code: '61'
        },
        {
            name: t('NewZealand'),
            value: t('NewZealand'),
            code: '64'
        },
        {
            name: t('Albania'),
            value: t('Albania'),
            code: '355'
        },
        {
            name: t('Austria'),
            value: t('Austria'),
            code: '43'
        },
        {
            name: t('Azerbaijan'),
            value: t('Azerbaijan'),
            code: '994'
        },
        {
            name: t('Belgium'),
            value: t('Belgium'),
            code: '32'
        },
        {
            name: t('Bulgaria'),
            value: t('Bulgaria'),
            code: '359'
        },
        {
            name: t('Croatia'),
            value: t('Croatia'),
            code: '385'
        },
        {
            name: t('Czech'),
            value: t('Czech'),
            code: '420'
        },
        {
            name: t('Denmark'),
            value: t('Denmark'),
            code: '45'
        },
        {
            name: t('Estonia'),
            value: t('Estonia'),
            code: '372'
        },
        {
            name: t('Finland'),
            value: t('Finland'),
            code: '358'
        },
        {
            name: t('France'),
            value: t('France'),
            code: '33'
        },
        {
            name: t('Georgia'),
            value: t('Georgia'),
            code: '995'
        },
        {
            name: t('Germany'),
            value: t('Germany'),
            code: '49'
        },
        {
            name: t('Gibraltar'),
            value: t('Gibraltar'),
            code: '350'
        },
        {
            name: t('Greece'),
            value: t('Greece'),
            code: '30'
        },
        {
            name: t('Hungary'),
            value: t('Hungary'),
            code: '36'
        },
        {
            name: t('Iceland'),
            value: t('Iceland'),
            code: '354'
        },
        {
            name: t('Ireland'),
            value: t('Ireland'),
            code: '353'
        },
        {
            name: t('Italy'),
            value: t('Italy'),
            code: '39'
        },
        {
            name: t('Kazakhstan'),
            value: t('Kazakhstan'),
            code: '7'
        },
        {
            name: t('Latvia'),
            value: t('Latvia'),
            code: '371'
        },
        {
            name: t('Lithuania'),
            value: t('Lithuania'),
            code: '370'
        },
        {
            name: t('Luxembourg'),
            value: t('Luxembourg'),
            code: '352'
        },
        {
            name: t('Malta'),
            value: t('Malta'),
            code: '356'
        },
        {
            name: t('Netherlands'),
            value: t('Netherlands'),
            code: '31'
        },
        {
            name: t('Norway'),
            value: t('Norway'),
            code: '47'
        },
        {
            name: t('Poland'),
            value: t('Poland'),
            code: '48'
        },
        {
            name: t('Romania'),
            value: t('Romania'),
            code: '40'
        },
        {
            name: t('Russia'),
            value: t('Russia'),
            code: '7'
        },
        {
            name: t('Slovakia'),
            value: t('Slovakia'),
            code: '421'
        },
        {
            name: t('Slovenia'),
            value: t('Slovenia'),
            code: '386'
        },
        {
            name: t('Spain'),
            value: t('Spain'),
            code: '34'
        },
        {
            name: t('Sweden'),
            value: t('Sweden'),
            code: '46'
        },
        {
            name: t('Switzerland'),
            value: t('Switzerland'),
            code: '41'
        },
        {
            name: t('UK'),
            value: t('UK'),
            code: '44'
        },
        {
            name: t('Ukraine'),
            value: t('Ukraine'),
            code: '380'
        },
        {
            name: t('Uzbekistan'),
            value: t('Uzbekistan'),
            code: '998'
        },
        {
            name: t('Cote'),
            value: t('Cote'),
            code: '225'
        },
        {
            name: t('Egypt'),
            value: t('Egypt'),
            code: '20'
        },
        {
            name: t('Kenya'),
            value: t('Kenya'),
            code: '254'
        },
        {
            name: t('Mauritius'),
            value: t('Mauritius'),
            code: '230'
        },
        {
            name: t('Mozambique'),
            value: t('Mozambique'),
            code: '258'
        },
        {
            name: t('Namibia'),
            value: t('Namibia'),
            code: '264'
        },
        {
            name: t('SouthAfrica'),
            value: t('SouthAfrica'),
            code: '27'
        },
        {
            name: t('Sudan'),
            value: t('Sudan'),
            code: '249'
        },
        {
            name: t('Swaziland'),
            value: t('Swaziland'),
            code: '268'
        },
        {
            name: t('Uganda'),
            value: t('Uganda'),
            code: '256'
        },
        {
            name: t('Zimbabwe'),
            value: t('Zimbabwe'),
            code: '263'
        }
    ];

    return { PRODUCT, APP, SUBJECT, COUNTRY };
};

export default useContactDialog;
