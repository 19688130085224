import { memo } from 'react';
import { Plane } from '@react-three/drei';
import { useRotate } from 'hooks/useRotate';
import * as THREE from 'three';
import { useMainContext } from 'hooks/useMainContext';
const CloudSmoke = ({ position, scale, opacity, layers = 1 }: any) => {
    const { textures } = useMainContext();
    const { c1 } = textures;
    const smokeRef = useRotate();

    return (
        <Plane
            ref={smokeRef.ref}
            args={[11.3, 10]}
            position={position}
            scale={scale}
            layers={layers}
        >
            <meshBasicMaterial
                map={c1}
                transparent={true}
                side={THREE.FrontSide}
                opacity={opacity}
            />
        </Plane>
    );
};

export default memo(CloudSmoke);
