import { Html } from '@react-three/drei';
import { ReactComponent as Infinity } from '../../../../assets/icons/infinity.svg';
import { ReactComponent as Infinity2 } from '../../../../assets/icons/infinity2.svg';
import './guide.scss';
import { memo } from 'react';
import GuideTitle from './GuideTitle';
import { useTranslation } from 'react-i18next';
import { useDetectGPU } from '@react-three/drei';
import useGuide from './useGuide';
import { useMainContext } from 'hooks/useMainContext';

const Guide = () => {
    const { isMobile } = useDetectGPU();
    const { t } = useTranslation();
    const { center } = useMainContext();

    const { guideStatus, yearArr, awardArr, infinity, titleColor, guideColor } =
        useGuide();

    return (
        <>
            <Html wrapperClass="ui_Btn">
                <div
                    className="sceneTwo_guide"
                    style={{
                        visibility: isMobile ? 'hidden' : 'visible',
                        transform: guideStatus
                            ? 'translateX(0px)'
                            : 'translateX(-300px)'
                    }}
                >
                    <div className="infinity_1">
                        <Infinity />
                    </div>
                    <div className="infinity_2">
                        <Infinity2 ref={infinity as any} />
                    </div>
                    <div className="guide_main">
                        <GuideTitle
                            status={titleColor[0]}
                            title={t('ABOUTBBIN')}
                            click={() => {

                                center.hallwayPercentTarget.x = 0;
                            }}
                        />

                        <div style={{ height: '24px', marginBottom: '1rem' }}>
                            <div className="guide_line" />
                        </div>

                        <GuideTitle
                            status={titleColor[1]}
                            title={t('YearlyBrandVision')}
                            click={() => {

                                center.hallwayPercentTarget.x = 0.1;
                            }}
                        />

                        <div style={{ display: 'flex', marginBottom: '1rem' }}>
                            <div
                                style={{
                                    height: '150px'
                                }}
                            >
                                <div className="guide_line" />
                            </div>

                            <div className="guide_item_group">
                                {yearArr.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="guide_item"
                                            style={
                                                guideColor[index + 1] &&
                                                titleColor[1]
                                                    ? { color: '#000' }
                                                    : { color: '#fff' }
                                            }
                                            onClick={() => {

                                                item.click();
                                            }}
                                        >
                                            {item.name}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <GuideTitle
                            status={titleColor[2]}
                            title={t('HonorsAndAwards')}
                            click={() => {

                                center.hallwayPercentTarget.x = 0.5;
                            }}
                        />

                        <div style={{ display: 'flex' }}>
                            <div
                                style={{
                                    height: '160px'
                                    // marginBottom: '1rem'
                                }}
                            >
                                <div className="guide_line" />
                            </div>

                            <div className="guide_item_group">
                                {awardArr.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="guide_item"
                                            style={
                                                guideColor[index + 6] &&
                                                titleColor[2]
                                                    ? { color: '#000' }
                                                    : { color: '#fff' }
                                            }
                                            onClick={() => {

                                                item.click();
                                            }}
                                        >
                                            {item.name}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Html>
        </>
    );
};

export default memo(Guide);
