import { Text } from '@react-three/drei';
import YearsGroup from '../../YearsGroup/YearsGroup';
import BottomArtModelTwo from 'components/models/BottomArtModelTwo/BottomArtModelTwo';
import CloudSmoke from 'components/models/CloudSmoke/CloudSmoke';
import useHallwayTwo from '../useHallwayTwo';
import { useDetectGPU } from '@react-three/drei';
import { memo } from 'react';
const HallwayTwoPc = () => {
    const { ARR2019, ARR2021, ARR2023 } = useHallwayTwo();
    const { isMobile } = useDetectGPU();

    const fontSizeConfig = {
        default: {
            title: 1,
            year: 0.2
        },
        '576': {
            title: 1,
            year: 0.2
        },
        '992': {
            title: 1,
            year: 0.2
        }
    };

    const fontSize = window.innerWidth >= 992
        ? fontSizeConfig['992']
        : window.innerWidth >= 576
            ? fontSizeConfig['576']
            : fontSizeConfig.default;

    if (isMobile) return <></>;
    return (
        <>
            <BottomArtModelTwo position={[0.094, -82.2, -37.118]} />

            {/* DEV */}
            {/* <Plane
                args={[10, 10]}
                position={[30, -81, -30]}
                layers={1}
            >
                <meshBasicMaterial
                    color={'#2b8bf8'}
                    side={THREE.DoubleSide}
                />
            </Plane> */}

            <Text
                layers={1}
                characters="Yearly"
                position={[0, -80, -70]}
                color={'#ef8e35'}
                font="/leading/leadingPageAssets/fonts/BoonTook-Regular.ttf"
                fontSize={fontSize.title}
                letterSpacing={-0.03}
            >
                Yearly
            </Text>
            <Text
                layers={1}
                characters="Brand Vision"
                position={[0, -81, -70]}
                color={'#ef8e35'}
                font="/leading/leadingPageAssets/fonts/BoonTook-Regular.ttf"
                fontSize={fontSize.title}
                letterSpacing={-0.03}
            >
                Brand Vision
            </Text>
            <Text
                layers={1}
                font="/leading/leadingPageAssets/fonts/DIN Alternate Bold OK.ttf"
                characters="2019 - 2023"
                color={'#ef8e35'}
                position={[0, -81.8, -70]}
                fontSize={fontSize.year}
                letterSpacing={0.5}
            >
                2019 - 2023
            </Text>

            {/* 2019 */}
            <group>
                {ARR2019.map((item, index) => {
                    return (
                        <CloudSmoke
                            key={index}
                            {...item}
                        />
                    );
                })}
            </group>

            {/* 2023 */}
            <group position={[0, 0, 0]}>
                {ARR2023.map((item, index) => {
                    return (
                        <CloudSmoke
                            key={index}
                            {...item}
                        />
                    );
                })}
            </group>

            {/* 2021 */}
            <group>
                {ARR2021.map((item, index) => {
                    return (
                        <CloudSmoke
                            key={index}
                            {...item}
                        />
                    );
                })}
            </group>

            <YearsGroup />
        </>
    );
};

export default memo(HallwayTwoPc);
