import { useFrame } from '@react-three/fiber';
import { DialogContent } from './HallwayVideoPlane';
import { handleTextTransform } from 'utils/handleTextTransform';
import * as THREE from 'three';
import { useState, useMemo } from 'react';

const useHallwayVideoPlane = (
    dialogContent: DialogContent,
    center: any,
    ref: any,
    textRef: any,
    textRef2: any,
    setContentStatus: any,
    position: any
) => {
    const [dialogStatus, setDialogStatus] = useState(false);

    const {
        title,
        textOneXOffset,
        textOneYOffset = 0,
        textTwoXOffset,
        textTwoYOffset = 0
    } = dialogContent;

    const opacityOrigine = new THREE.Vector2(0, 0);
    const opacityTarget = new THREE.Vector2(0, 0);

    const bbinOpacityOrigine = new THREE.Vector2(1, 0);
    const bbinOpacityTarget = new THREE.Vector2(1, 0);

    useFrame(() => {
        const planePositionZ = ((ref.current?.parent?.position.z as any) +
            ref.current?.position.z) as any;

        if (planePositionZ > -9 && planePositionZ < 0.5) {
            center.hallwayText = {
                type: title,
                opacity: 1,
                color: '#e1deec'
            };
        } else {
            if (center.hallwayText.type === title) {
                document.body.className = '';
                center.hallwayText = {
                    type: title,
                    opacity: 0,
                    color: '#e1deec'
                };
            }
        }

        if (center.where === 'hallway' && center.isMoving) {
            setDialogStatus(false);
            setContentStatus(false);
        }
    });

    useFrame(() => {
        const textTransformValue = handleTextTransform(title);

        textRef.current.text = textTransformValue.textOne;
        textRef2.current.text = textTransformValue.textTwo;

        if (center.hallwayText.type === title) {
            opacityTarget.set(center.hallwayText.opacity, 0);
        }
        if (title === 'BBIN') {
            bbinOpacityTarget.set(center.hallwayText.opacity, 0);
        }

        if (center.hallwayText.type !== title) {
            bbinOpacityTarget.set(1, 0);
        }

        opacityOrigine.lerp(opacityTarget, 0.08);
        textRef.current.fillOpacity = opacityOrigine.x;
        textRef2.current.fillOpacity = opacityOrigine.x;

        // circleRef.current.fillOpacity = opacityOrigine.x;
        // circleRef2.current.fillOpacity = opacityOrigine.x;

        bbinOpacityOrigine.lerp(bbinOpacityTarget, 0.15);
        if (title === 'BBIN') {
            ref.current.material.opacity = bbinOpacityOrigine.x;
        }

        if (center.hallwayfogStatus === 0) {
            textRef.current.color = '#B2B2B4';
        }
        if (center.hallwayfogStatus === 1) {
            textRef.current.color = '#ef8e35';
        }
        if (center.hallwayfogStatus === 2) {
            textRef.current.color = '#4cd8c0';
        }
        textRef2.current.color = '#494949';

        textRef.current.textAlign = textTransformValue.align;
        textRef2.current.textAlign = textTransformValue.align;
    });

    const handleClick = () => {
        if (center.scrollPause || dialogStatus) return;
        if (
            title !== center.hallwayText.type ||
            center.hallwayText.opacity === 0
        ) return;

        setDialogStatus(true);
        center.scrollPause = true;
        setTimeout(() => {
            setContentStatus(true);
        }, 1000);
    };

    const handlePointerMove = () => {
        if (
            title !== center.hallwayText.type ||
            center.hallwayText.opacity === 0 ||
            center.isMoving
        )
            return;
        document.body.className = 'pointer';
    };

    const handlePointerLeave = () => {
        if (
            title !== center.hallwayText.type ||
            center.hallwayText.opacity === 0
        )
            return;
        document.body.className = '';
    };

    const textArr = useMemo(
        () => [
            {
                ref: textRef,
                layers: 1,
                font: '/leading/leadingPageAssets/fonts/DIN 2014 Bold.ttf',
                characters: '01239IGAERBSPChtluropeamingwds',
                position: [
                    position[0] - textOneXOffset,
                    position[1] - textOneYOffset,
                    position[2]
                ],
                fontSize: 0.5,
                onClick: handleClick,
                onPointerMove: handlePointerMove,
                onPointerLeave: handlePointerLeave
            },
            {
                ref: textRef2,
                layers: 1,
                font: '/leading/leadingPageAssets/fonts/DIN Alternate Bold OK.ttf',
                characters: '01239IGAERBSPChtlurybopeamingwdsz',
                position: [
                    position[0] - textTwoXOffset,
                    position[1] - textTwoYOffset,
                    position[2]
                ],
                fontSize: 0.25,
                onClick: handleClick,
                onPointerMove: handlePointerMove,
                onPointerLeave: handlePointerLeave
            }
        ],
        []
    );
    return {
        textArr,
        dialogStatus,
        setDialogStatus,
        handleClick,
        handlePointerMove,
        handlePointerLeave
    };
};

export default useHallwayVideoPlane;
