import { memo } from 'react';
import BtmFloor from './BtmFloor';
import BtmWall from './BtmWall';
// import BtmWater from './BtmWater';
import BtmGold from './BtmGold';

const BtmGround = (props: any) => {
    return (
        <group {...props}>
            <BtmFloor />
            <BtmGold />
            <BtmWall />
            {/* <BtmWater /> */}
        </group>
    );
};

export default memo(BtmGround);
