import { useMainContext } from 'hooks/useMainContext';
import { useMemo, useState, useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { handleGuideTextColor } from './utils/handleGuideTextColor';

const useGuide = () => {
    const { center } = useMainContext();
    const [guideColor, setGuideColor] = useState([
        1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ]);
    const infinity = useRef<any>();
    const [titleColor, setTitleColor] = useState([1, 0, 0]);
    const [guideStatus, setGuideStatus] = useState(false);

    const yearArr = useMemo(
        () => [
            {
                name: '- 2023',
                click: () => {
                    center.hallwayPercentTarget.x = 0.2;
                }
            },
            {
                name: '- 2022',
                click: () => {
                    center.hallwayPercentTarget.x = 0.25;
                }
            },
            {
                name: '- 2021',
                click: () => {
                    center.hallwayPercentTarget.x = 0.3;
                }
            },
            {
                name: '- 2020',
                click: () => {
                    center.hallwayPercentTarget.x = 0.35;
                }
            },
            {
                name: '- 2019',
                click: () => {
                    center.hallwayPercentTarget.x = 0.4;
                }
            }
        ],
        []
    );

    const awardArr = useMemo(
        () => [
            {
                name: '- IGA',
                click: () => {
                    center.hallwayPercentTarget.x = 0.6;
                }
            },
            {
                name: '- EGR B2B',
                click: () => {
                    center.hallwayPercentTarget.x = 0.65;
                }
            },
            {
                name: '- AGA',
                click: () => {
                    center.hallwayPercentTarget.x = 0.7;
                }
            },
            {
                name: '- Europe Gaming Awards',
                click: () => {
                    center.hallwayPercentTarget.x = 0.75;
                }
            },
            {
                name: '- Starlet Awards',
                click: () => {
                    center.hallwayPercentTarget.x = 0.8;
                }
            },
            {
                name: '- SPiCE Philippines',
                click: () => {
                    center.hallwayPercentTarget.x = 0.85;
                }
            }
        ],
        []
    );

    const handleScrollForInfinity = () => {
        if (!infinity.current) return;
        const scrollTargetDeep = center.hallwayPercentOrgine.x;
        // document.getElementsByClassName('scrollTargetDeep')[0].clientHeight;

        const hallwayTotalDeep = 1; // 從頭走到尾總長的百分比
        const scrollPositionByPercents = scrollTargetDeep % hallwayTotalDeep;
        const guideValue = 188 * Math.abs(scrollPositionByPercents) + 188;
        infinity.current.style.strokeDasharray = guideValue;
        // setGuide(guideValue);
    };

    const handleTitleColor = () => {
        const checkHallwayPart = center.hallwayfogStatus;

        switch (checkHallwayPart) {
            case 0:
                setTitleColor([1, 0, 0]);
                break;
            case 1:
                setTitleColor([0, 1, 0]);
                break;
            case 2:
                setTitleColor([0, 0, 1]);
                break;

            default:
                setTitleColor([1, 0, 0]);
                break;
        }
    };

    useFrame(({ camera }) => {
        handleGuideTextColor(center, setGuideColor);

        camera.position.y === -83 && setGuideStatus(true);
        center.isMoving && setGuideStatus(false);

        handleTitleColor();
        handleScrollForInfinity();
    });

    return { guideStatus, yearArr, awardArr, infinity, titleColor, guideColor };
};

export default useGuide;
