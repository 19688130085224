// ss/models/Sphere/Sphere';
// import { turnToDegrees } from 'utils/turnToDegrees';
// import FeatherContainer from 'components/models/Feather/FeatherContainer';
// import Rays from 'components/models/Rays/Rays';
// import FeatherFront from 'components/models/Feather/FeatherFront/FeatherFront';
// import SceneOneRaysContainer from 'components/models/RaysContainer/SceneOneRaysContainer';
// import SceneCloud from 'components/models/SceneCloud/SceneCloud';
import * as THREE from 'three';
import { Plane } from '@react-three/drei';
import { memo, useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { useDetectGPU } from '@react-three/drei';
// import { useMainContext } from 'hooks/useMainContext';

// import { useControls } from 'leva';

const SceneOne = () => {
    // const { textures } = useMainContext();
    const { isMobile } = useDetectGPU();
    // const { position, rotation } = useControls({
    //     position: { value: [0, -0.6, 28.9], step: 0.1 },
    //     rotation: { value: [0, 0, 0], step: 0.1 }
    // });
    const skyRef = useRef<any>();

    useFrame(({ clock }) => {
        if (!skyRef.current || isMobile) return;
        const time = clock.getElapsedTime();
        skyRef.current.position.x = 0.8 + 2 * Math.sin(time / 3);
    });
    return (
        <>
            {/* layer 1 */}
            <Plane
                ref={skyRef}
                args={[520, 240]}
                position={[0.8, 5.5, -267.3]}
            >
                <meshBasicMaterial color={new THREE.Color('#ffffff')} />
            </Plane>

            {/* <Plane
                args={[20, 33]}
                rotation={[turnToDegrees(-90), 0, 0]}
                position={[0, -0.6, 28.9]}
            >
                <meshBasicMaterial map={groundWater} />
            </Plane> */}
            {/* <Plane
                args={[33, 6.5]}
                rotation={[
                    turnToDegrees(90),
                    turnToDegrees(0),
                    turnToDegrees(-90)
                ]}
                position={[0, -0.6, 29.1]}
                layers={0}
            >
                <meshBasicMaterial side={THREE.BackSide} />
            </Plane> */}
            {/* {!isMobile && <Rays />}
            <Base />
            <Building />
            <Cloth />
            <Door />
            <Floor />
            <Rigui />
            <Sphere />
            {!isMobile && <SceneCloud />}

            <SceneOneRaysContainer />

            <FeatherContainer position={[0, 0, -10]} />
            {!isMobile && <FeatherFront position={[0, 0, 45]} />}

            <Plane
                args={[11, 11]}
                position={[0, -1.5, -10.4]}
                layers={0}
                rotation={[turnToDegrees(-90), turnToDegrees(0), 0]}
            >
                <meshBasicMaterial
                    map={textures.waterCircle}
                    side={THREE.FrontSide}
                />
            </Plane> */}
        </>
    );
};

export default memo(SceneOne);
