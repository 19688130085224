import './loadingPage.scss';
import { memo, useEffect, useState } from 'react';
import { useDetectGPU } from '@react-three/drei';
import { Loader } from '../../utils/loader';

const LoadingPage = ({ setLoaderStatus }: any) => {
    const { isMobile } = useDetectGPU();
    const [progress, setProgress] = useState(0);
    const [ AssetsLoader ] = useState<any>(new Loader());

    AssetsLoader.setOnUpdate(({progress}: any) => {
        if (progress >= 100) {
            setProgress(100);

            // 因 2024/02/02 回饋載入太慢故移除
            // setTimeout(() => {
            setLoaderStatus(false);
            // }, 3000);
        } else setProgress(progress);
    });

    useEffect(() => {
        if (AssetsLoader) {
            /* 圖片檔案產生器 > 物件 > 並自動組裝置 Loader */
            const list = [
                'bg.jpg',
                'bg_m.jpg'
            ];

            const imgData = AssetsLoader.generator(
                list,
                '/leading/leadingPageAssets/loadingPage'
            );

            /* 載入相應圖檔物件包 */
            AssetsLoader.load(imgData);
        }
    }, []);

    return (
        <div
            className="loadingPage"
            style={{
                backgroundImage: `url("/leading/leadingPageAssets/loadingPage/bg${isMobile ? '_m' : ''}.jpg")`
            }}
        >
            <div className="loadingPage-logo">
                <img
                    src="/leading/leadingPageAssets/loadingPage/logo_animation.png"
                    alt="goBetter"
                />
            </div>
            <div className="loadingPage-loadingCircle">
                <img
                    src="/leading/leadingPageAssets/loadingPage/dots_animation.png"
                    alt="goBetter"
                />
            </div>
            <div className="loadingPage-progress">
                <img
                    className="logo"
                    src="/leading/leadingPageAssets/loadingPage/logo_static.png"
                    alt="logo"
                />

                <p className="loadingPage-progress-percentage">
                    {`${progress.toFixed(0)}%`}
                </p>
            </div>

            <p className="hint">建议减少分页，以获得最佳浏览体验。</p>

            {/* 2023/08/24 設計指示移除 */}
            {/* <div
                        className="loadingPage-visit-bbin"
                        onClick={() => {
                            window.location.href =
                                '/bbin/cn';
                        }}
                    >
                        VISIT BBIN OFFICIAL
                        <div
                            style={{
                                height: '1px',
                                display: 'flex',
                                paddingTop: '4px'
                            }}
                        >
                            <div className="span_one"></div>
                            <div className="span_two"></div>
                        </div>
                    </div> */}
        </div>
    );
};

export default memo(LoadingPage);
