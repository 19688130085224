import BasicModel from '../BasicModel/BasicModel';
import useDracoLoader from 'hooks/useDracoLoader';
import { Vector3 } from '@react-three/fiber';
import { memo } from 'react';
import { useMainContext } from 'hooks/useMainContext';
import { Vector2 } from 'three';
interface BottomArtModelOneProps {
    position: number[];
}

const BottomArtModelOne = ({ position }: BottomArtModelOneProps) => {
    const gltf = useDracoLoader(
        '/leading/leadingPageAssets/models/Bottom/Abstract01/Abstract01.gltf'
    );

    const { textures } = useMainContext();
    const {
        bottomArtModelOnemap,
        bottomArtModelOnemetalnessMap,
        bottomArtModelOnenormalMap
        // bottomArtModelOneenvMap
    } = textures;

    return (
        <group
            position={position as Vector3}
            layers={1}
        >
            {gltf.scene.children.map((item: any, index: any) => {
                if (item.name === 'Abstract01') {
                    return (
                        <BasicModel
                            key={index}
                            item={item}
                            maps={{
                                map: bottomArtModelOnemap,
                                metalnessMap: bottomArtModelOnemetalnessMap,
                                lightMap: bottomArtModelOnemetalnessMap,
                                normalMap: bottomArtModelOnenormalMap,
                                reflectivity: 0.3,
                                roughness: 0.8,
                                metalness: 1,
                                normalScale: new Vector2(-1, 2.7)
                            }}
                            layers={1}
                        />
                    );
                }

                return (
                    <BasicModel
                        key={index}
                        item={item}
                        maps={{
                            reflectivity: 0.46,
                            roughness: 0.23,
                            metalness: 0.86,
                            color: '#CF864A'
                        }}
                        layers={1}
                    />
                );
            })}
        </group>
    );
};

export default memo(BottomArtModelOne);
