import { turnToDegrees } from 'utils/turnToDegrees';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useMainContext } from 'hooks/useMainContext';
// import { useMainContext } from 'hooks/useMainContext';

const DEFAULT_POSITION_Z = -110;
const DEFAULT_NOTICE_POSITION_Z = -105;
const SPACING = 20;

const useYearsGroup = () => {
    const { t, i18n } = useTranslation();
    const { textures } = useMainContext();
    const {
        year2019Texture,
        year2020Texture,
        year2021Texture,
        year2022Texture,
        year2023Texture
    } = textures;

    const GOBETTER = useMemo(
        () => ({
            args: [4, 3],
            position: [-3, -81, DEFAULT_POSITION_Z],
            noticePointPosition: [
                -0.5,
                -83.75,
                DEFAULT_NOTICE_POSITION_Z + 1.2
            ],
            noticePointLineHeight: 1.45,
            noticePointLinePosition: [0, 1.975, -6.73],
            rotation: [0, turnToDegrees(25), 0],
            dialogContent: {
                title: '2023',
                subtitle: 'GO BETTER',
                type: 'youtube',
                textureType: 'video',
                src: i18n.language === 'en' ? 'https://www.youtube.com/embed/R81C3EeSl6M' : 'https://www.youtube.com/embed/8xF326FpQJg',
                texture: year2023Texture,
                textOneXOffset: -3.5,
                textTwoXOffset: -3.6,
                textOneYOffset: -0.32,
                textTwoYOffset: 0.18
            },
            content: <>{t('year-2023')}</>
        }),
        [i18n.language, t]
    );

    const THEGAMINGBEAT = useMemo(
        () => ({
            args: [4, 3],
            position: [3, -81, DEFAULT_POSITION_Z - SPACING],
            noticePointPosition: [
                0.5,
                -83.75,
                DEFAULT_NOTICE_POSITION_Z - SPACING + 1.2
            ],
            noticePointLineHeight: 1.45,
            noticePointLinePosition: [0, 1.975, -6.73],

            rotation: [0, turnToDegrees(-25), 0],
            dialogContent: {
                title: '2022',
                subtitle: 'THE GAMING BEAT',
                type: 'image',
                textureType: 'video',
                src: '/leading/leadingPageAssets/images/hallway/year/2022/2022.png',
                texture: year2022Texture,
                textOneXOffset: 3.5,
                textTwoXOffset: 4,
                textOneYOffset: -0.32,
                textTwoYOffset: 0.18
            },
            content: <>{t('year-2022')}</>
        }),
        [i18n.language, t]
    );

    const MOVEBEYONDDRIVECHANGE = useMemo(
        () => ({
            args: [4, 3],
            position: [-3, -81, DEFAULT_POSITION_Z - 2 * SPACING],
            noticePointPosition: [
                -0.5,
                -83.75,
                DEFAULT_NOTICE_POSITION_Z - 2 * SPACING + 1.2
            ],
            noticePointLineHeight: 1.45,
            noticePointLinePosition: [0, 1.975, -6.73],
            rotation: [0, turnToDegrees(25), 0],
            dialogContent: {
                title: '2021',
                subtitle: 'MOVE BEYOND DRIVE CHANGE',
                type: 'youtube',
                textureType: 'video',
                src: 'https://www.youtube.com/embed/c4YSUAxmvYk',
                texture: year2021Texture,
                textOneXOffset: -3.5,
                textTwoXOffset: -4.6,
                textOneYOffset: -0.32,
                textTwoYOffset: 0.18
            },
            content: <>{t('year-2021')}</>
        }),
        [i18n.language, t]
    );

    const UP = useMemo(
        () => ({
            args: [4, 3],
            position: [3, -81, DEFAULT_POSITION_Z - 3 * SPACING],
            noticePointPosition: [
                0.5,
                -83.75,
                DEFAULT_NOTICE_POSITION_Z - 3 * SPACING + 1.2
            ],
            noticePointLineHeight: 1.45,
            noticePointLinePosition: [0, 1.975, -6.73],
            rotation: [0, turnToDegrees(-25), 0],
            dialogContent: {
                title: '2020',
                subtitle: 'UP',
                type: 'youtube',
                textureType: 'video',
                src: 'https://www.youtube.com/embed/bGNzurCbrNM',
                texture: year2020Texture,
                textOneXOffset: 3.5,
                textTwoXOffset: 3.15,
                textOneYOffset: -0.32,
                textTwoYOffset: 0.18
            },
            content: <>{t('year-2020')}</>
        }),
        [i18n.language, t]
    );

    const JUMP = useMemo(
        () => ({
            args: [4, 3],
            position: [-3, -81, DEFAULT_POSITION_Z - 4 * SPACING],
            noticePointPosition: [
                -0.5,
                -83.75,
                DEFAULT_NOTICE_POSITION_Z - 4 * SPACING + 1.2
            ],
            noticePointLineHeight: 1.45,
            noticePointLinePosition: [0, 1.975, -6.73],
            rotation: [0, turnToDegrees(25), 0],
            dialogContent: {
                title: '2019',
                subtitle: 'JUMP / 20 YEARS',
                type: 'youtube',
                textureType: 'video',
                src: 'https://www.youtube.com/embed/qkgnM8OBAUw',
                texture: year2019Texture,
                textOneXOffset: -3.5,
                textTwoXOffset: -3.9,
                textOneYOffset: -0.32,
                textTwoYOffset: 0.18
            },
            content: <>{t('year-2019')}</>
        }),
        [i18n.language, t]
    );
    const res = useMemo(() => {
        return [GOBETTER, THEGAMINGBEAT, MOVEBEYONDDRIVECHANGE, UP, JUMP];
    }, [i18n.language, t]);

    return res;
};

export default useYearsGroup;
