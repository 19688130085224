import { useEffect, useRef, Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { ResizeObserver } from '@juggle/resize-observer';
import ScreenOrientation from 'components/ScreenOrientation/ScreenOrientation';
import CanvasContainer from './CanvasContainer/CanvasContainer';

const CanvasEntrace = ({
    hallwayPositionX,
    setCanvasStatus,
    setHallwayToLoby
}: any) => {
    const canvasRef = useRef<any>();

    const hamdleResize = () => {
        if (!canvasRef.current) return;
        canvasRef.current.style.width = window.innerWidth;
        canvasRef.current.style.height = window.innerHeight;
    };

    useEffect(() => {
        window.addEventListener('resize', hamdleResize);

        return () => {
            window.removeEventListener('resize', hamdleResize);
        };
    }, []);

    return (
        <>
            <Suspense>
                <Canvas
                    ref={canvasRef}
                    flat
                    shadows={false}
                    className="experience"
                    camera={{
                        fov: 30,
                        near: 0.1,
                        far: 3300
                    }}
                    gl={{
                        antialias: true,
                        // preserveDrawingBuffer: true,
                        // logarithmicDepthBuffer: true,
                        powerPreference: 'high-performance'
                    }}
                    dpr={[1, 1]}
                    resize={{ polyfill: ResizeObserver }}
                >
                    <CanvasContainer
                        hallwayPositionX={hallwayPositionX}
                        setCanvasStatus={setCanvasStatus}
                        setHallwayToLoby={setHallwayToLoby}
                    />
                </Canvas>
            </Suspense>
            <ScreenOrientation />
        </>
    );
};

export default CanvasEntrace;
