import BasicModel from 'components/models/BasicModel/BasicModel';
import { memo } from 'react';
import useDracoLoader from 'hooks/useDracoLoader';
import { Vector2 } from 'three';
import { useDetectGPU } from '@react-three/drei';
import { useMainContext } from 'hooks/useMainContext';

const BtmFloor = () => {
    const { isMobile } = useDetectGPU();
    const checkData = isMobile
        ? '/leading/leadingPageAssets/models/Bottom/mobile/Floor/BtmFloor.gltf'
        : '/leading/leadingPageAssets/models/Bottom/Floor/BtmFloor.gltf';

    const gltf = useDracoLoader(checkData);

    const { textures } = useMainContext();
    const { btmFloormap, btmFloornormalMap, btmFloorenvMap } = textures;

    return (
        <>
            {gltf.scene.children.map((item: any, index: any) => {
                return (
                    <BasicModel
                        key={index}
                        item={item}
                        maps={{
                            map: btmFloormap,
                            normalMap: btmFloornormalMap,
                            envMap: btmFloorenvMap,

                            reflectivity: 0,
                            roughness: 0.18,
                            metalness: 0.16,

                            normalScale: new Vector2(0.49, -16.02)
                        }}
                        layers={1}
                    />
                );
            })}
        </>
    );
};

export default memo(BtmFloor);
