import useDracoLoader from 'hooks/useDracoLoader';
import BasicModel from '../BasicModel/BasicModel';
import { Vector3 } from '@react-three/fiber';
import { Vector2 } from 'three';
import { memo } from 'react';
import { useMainContext } from 'hooks/useMainContext';

interface BottomArtModelThreeProps {
    position: number[];
}

const BottomArtModelThree = ({ position }: BottomArtModelThreeProps) => {
    const gltf = useDracoLoader(
        '/leading/leadingPageAssets/models/Bottom/Abstract03/Abstract03.gltf'
    );

    const { textures } = useMainContext();
    const {
        bottomArtModelThreemap,
        bottomArtModelThreemetalnessMap,
        bottomArtModelThreenormalMap
        // bottomArtModelThreeenvMap
    } = textures;

    return (
        <group
            position={position as Vector3}
            layers={1}
            scale={1.3}
        >
            {gltf.scene.children.map((item: any, index: any) => {
                if (item.name === 'Spiral') {
                    return (
                        <BasicModel
                            key={index}
                            item={item}
                            maps={{
                                map: bottomArtModelThreemap,
                                metalnessMap: bottomArtModelThreemetalnessMap,
                                lightMap: bottomArtModelThreemetalnessMap,
                                normalMap: bottomArtModelThreenormalMap,
                                // envMap: bottomArtModelThreeenvMap,

                                reflectivity: 1,
                                roughness: 1,
                                metalness: 0.18,
                                normalScale: new Vector2(-1, 0)
                            }}
                            layers={1}
                        />
                    );
                }
                return (
                    <BasicModel
                        key={index}
                        item={item}
                        maps={{
                            color: '#BF2B2B'
                        }}
                        layers={1}
                    />
                );
            })}
        </group>
    );
};

export default memo(BottomArtModelThree);
