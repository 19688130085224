export const handleTextTransform = (type: string) => {
    switch (type) {
        case 'BBIN':
            return {
                textOne: '',
                textTwo: '',
                align: 'center'
            };

        case '2023':
            return {
                textOne: '2023',
                textTwo: 'GO BETTER',
                align: 'left'
            };

        case '2022':
            return {
                textOne: '2022',
                textTwo: 'THE GAMING BEAT',
                align: 'right'
            };

        case '2021':
            return {
                textOne: '2021',
                textTwo: 'MOVE BEYOND DRIVE CHANGE',
                align: 'left'
            };

        case '2020':
            return {
                textOne: '2020',
                textTwo: 'UP',
                align: 'right'
            };

        case '2019':
            return {
                textOne: '2019',
                textTwo: 'JUMP / 20 YEARS',
                align: 'left'
            };

        case 'IGA':
            return {
                textOne: 'IGA',
                textTwo: 'Asian Gambling Awards\nby Gambling Insider',
                align: 'left'
            };

        case 'EGR B2B':
            return {
                textOne: 'EGR B2B',
                textTwo: 'Awards hosted\nby European Gaming Media and Events',
                align: 'right'
            };

        case 'AGA':
            return {
                textOne: 'AGA',
                textTwo: 'Awards co-founded\nby Asia Gaming Brief and G2E Asia',
                align: 'left'
            };

        case 'Europe Gaming Awards':
            return {
                textOne: 'Europe Gaming Awards',
                textTwo:
                    'Awards organized by SiGMA,\nthe European authority on gambling',
                align: 'right'
            };

        case 'Starlet Awards':
            return {
                textOne: 'Starlet Awards',
                textTwo:
                    'Awards presented by 5 Star & Co,\na multimedia marketing company\nin the gambling industry',
                align: 'left'
            };

        case 'SPiCE Philippines':
            return {
                textOne: 'SPiCE Philippines',
                textTwo: 'Awards presented by SPiCE,\nthe Philippine gambling\nexhibition organizer',
                align: 'right'
            };

        default:
            return {
                textOne: '',
                textTwo: '',
                align: 'center'
            };
    }
};
