import { turnToDegrees } from 'utils/turnToDegrees';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useMainContext } from 'hooks/useMainContext';
const DEFAULT_POSITION_Z = -110;
// const DEFAULT_NOTICE_POSITION_Y = -82.5;
const DEFAULT_NOTICE_POSITION_Z = -105;

const SPACING = 20;

const useAward = () => {
    const { t, i18n } = useTranslation();
    const { textures } = useMainContext();
    const {
        igaTexture,
        egrTexture,
        agaTexture,
        europeGamingAwardsTexture,
        starletAwardsTexture,
        PiCEPhilippinesTexture
    } = textures;

    const IGA = useMemo(
        () => ({
            args: [4, 3],
            position: [-3, -81, DEFAULT_POSITION_Z],
            noticePointPosition: [
                -0.5,
                -83.62,
                DEFAULT_NOTICE_POSITION_Z + 1.2
            ],
            noticePointLineHeight: 1.4,
            noticePointLinePosition: [0, 1.95, -6.73],
            rotation: [0, turnToDegrees(25), 0],
            dialogContent: {
                title: 'IGA',
                subtitle: t('award-IGA-subtitle'),
                type: 'image',
                src: '/leading/leadingPageAssets/images/hallway/IGA/IGA_1100x730.png',
                texture: igaTexture,
                textOneXOffset: -3.2,
                textTwoXOffset: -4.1,
                textTwoYOffset: 0.3,
                textOneYOffset: -0.4
            },
            content: (
                <>
                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {t('award-bbin')}
                    </span>
                    <br />
                    <br />
                    2023
                    <br />
                    {t('award-IGA-1')}
                    <br />
                    {t('award-IGA-2')}
                    <br />
                    {t('award-IGA-3')}
                    <br />
                    {t('award-IGA-4')}
                    <br />
                    {t('award-IGA-5')}
                    <br />
                    {t('award-IGA-6')}
                    <br />
                    <br />
                    {t('award-IGA-7')}
                    <br />
                    {t('award-IGA-8')}
                    <br />
                    {t('award-IGA-9')}
                    <br />
                    {t('award-IGA-10')}
                    <br />
                    {t('award-IGA-11')}
                    <br />
                    <br />
                    2017
                    <br />
                    {t('award-IGA-12')}
                    <br />
                    {t('award-IGA-13')}
                    <br />
                    {t('award-IGA-14')}
                </>
            )
        }),
        [i18n.language, t]
    );

    const EGR = useMemo(
        () => ({
            args: [4, 3],
            position: [3, -81, DEFAULT_POSITION_Z - SPACING],
            noticePointPosition: [
                0.5,
                -83.62,
                DEFAULT_NOTICE_POSITION_Z - SPACING + 1.2
            ],
            noticePointLineHeight: 1.4,
            noticePointLinePosition: [0, 1.95, -6.73],
            rotation: [0, turnToDegrees(-25), 0],
            dialogContent: {
                title: 'EGR B2B',
                subtitle: t('award-EGR-subtitle'),
                type: 'image',
                src: '/leading/leadingPageAssets/images/hallway/EGR/EGR_1100x730.png',
                texture: egrTexture,
                // textOneXOffset: -2,
                textOneXOffset: 3.8,
                textTwoXOffset: 4.9,
                textTwoYOffset: 0.3,
                textOneYOffset: -0.4
            },
            content: (
                <>
                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {t('award-bbin')}
                    </span>
                    <br />
                    <br />
                    2023
                    <br />
                    {t('award-EGR-1')}
                    <br />
                    {t('award-EGR-2')}
                    <br />
                    <br />
                    2022
                    <br />
                    {t('award-EGR-3')}
                    <br />
                    {t('award-EGR-4')}
                    <br />
                    <br />
                    2021
                    <br />
                    {t('award-EGR-5')}
                    <br />
                    {t('award-EGR-6')}
                    <br />
                    {t('award-EGR-7')}
                    <br />
                    <br />
                    2020
                    <br />
                    {t('award-EGR-8')}
                    <br />
                    {t('award-EGR-9')}
                    <br />
                    <br />
                    2019
                    <br />
                    {t('award-EGR-10')}
                    <br />
                    {t('award-EGR-11')}
                    <br />
                    <br />
                    2018
                    <br />
                    {t('award-EGR-12')}
                    <br />
                    {t('award-EGR-13')}
                    <br />
                    {t('award-EGR-14')}
                    <br />
                    <br />
                    2017
                    <br />
                    {t('award-EGR-15')}
                </>
            )
        }),
        [i18n.language, t]
    );

    const AGA = useMemo(
        () => ({
            args: [4, 3],
            position: [-3, -81, DEFAULT_POSITION_Z - 2 * SPACING],
            noticePointPosition: [
                -0.5,
                -83.62,
                DEFAULT_NOTICE_POSITION_Z - 2 * SPACING + 1.2
            ],
            noticePointLineHeight: 1.4,
            noticePointLinePosition: [0, 1.95, -6.73],
            rotation: [0, turnToDegrees(25), 0],
            dialogContent: {
                title: 'AGA',
                subtitle: t('award-AGA-subtitle'),
                type: 'image',
                src: '/leading/leadingPageAssets/images/hallway/AGA/AGA_1100x730.png',
                texture: agaTexture,
                // textOneXOffset: -2,
                textOneXOffset: -3.4,
                textTwoXOffset: -4.8,
                textTwoYOffset: 0.3,
                textOneYOffset: -0.4
            },
            content: (
                <>
                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {t('award-bbin')}
                    </span>
                    <br />
                    <br />
                    2023
                    <br />
                    {t('award-AGA-1')}
                    <br />
                    {t('award-AGA-2')}
                    <br />
                    {t('award-AGA-3')}
                    <br />
                    {t('award-AGA-4')}
                    <br />
                    <br />
                    2017
                    <br />
                    {t('award-AGA-5')}
                    <br />
                    <br />
                    2016
                    <br />
                    {t('award-AGA-6')}
                </>
            )
        }),
        [i18n.language, t]
    );
    const EuropeGamingAwards = useMemo(
        () => ({
            args: [4, 3],
            position: [3, -81, DEFAULT_POSITION_Z - 3 * SPACING],
            noticePointPosition: [
                0.5,
                -83.67,
                DEFAULT_NOTICE_POSITION_Z - 3 * SPACING + 1.2
            ],
            noticePointLineHeight: 1.4,
            noticePointLinePosition: [0, 1.95, -6.73],
            rotation: [0, turnToDegrees(-25), 0],
            dialogContent: {
                title: 'Europe Gaming Awards',
                subtitle: t('award-EGA-subtitle'),
                type: 'image',
                src: '/leading/leadingPageAssets/images/hallway/EuropeGamingAwards/Europe Gaming Awards_1100x730.png',
                texture: europeGamingAwardsTexture,
                textOneXOffset: 5.3,
                textTwoXOffset: 4.8,
                textTwoYOffset: 0.3,
                textOneYOffset: -0.4
            },
            content: (
                <>
                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {t('award-bbin')}
                    </span>
                    <br />
                    <br />
                    {t('award-EGA-1')}
                    <br />
                    {t('award-EGA-2')}
                </>
            )
        }),
        [i18n.language, t]
    );
    const StarletAwards = useMemo(
        () => ({
            args: [4, 3],
            position: [-3, -81, DEFAULT_POSITION_Z - 4 * SPACING],
            noticePointPosition: [
                -0.5,
                -83.67,
                DEFAULT_NOTICE_POSITION_Z - 4 * SPACING + 1.2
            ],
            noticePointLineHeight: 1.45,
            noticePointLinePosition: [0, 1.9, -6.73],
            rotation: [0, turnToDegrees(25), 0],
            dialogContent: {
                title: 'Starlet Awards',
                subtitle: t('award-Star-subtitle'),
                type: 'image',
                src: '/leading/leadingPageAssets/images/hallway/StarletAwards/Starlet Awards_1100x730.png',
                texture: starletAwardsTexture,

                textOneXOffset: -4.5,
                textTwoXOffset: -4.7,
                textOneYOffset: -0.35,
                textTwoYOffset: 0.5
            },
            content: (
                <>
                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {t('award-bbin')}
                    </span>
                    <br />
                    <br />
                    {t('award-Star-1')}
                    <br />
                    {t('award-Star-2')}
                </>
            )
        }),
        [i18n.language, t]
    );
    const SPiCEPhilippines = useMemo(
        () => ({
            args: [4, 3],
            position: [3, -81, DEFAULT_POSITION_Z - 5 * SPACING],
            noticePointPosition: [
                0.5,
                -83.62,
                DEFAULT_NOTICE_POSITION_Z - 5 * SPACING + 1.2
            ],
            noticePointLineHeight: 1.45,
            noticePointLinePosition: [0, 1.9, -6.73],
            rotation: [0, turnToDegrees(-25), 0],
            dialogContent: {
                title: 'SPiCE Philippines',
                subtitle: t('award-spice-subtitle'),
                type: 'image',
                src: '/leading/leadingPageAssets/images/hallway/SPiCEPhilippines/SPiCE Philippines_1100x730.png',
                texture: PiCEPhilippinesTexture,

                textOneXOffset: 4.8,
                textTwoXOffset: 4.45,
                textOneYOffset: -0.35,
                textTwoYOffset: 0.5
            },
            content: (
                <>
                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {t('award-bbin')}
                    </span>
                    <br />
                    <br />
                    2022
                    <br />
                    {t('award-spice-1')}
                    <br />
                    {t('award-spice-2')}
                </>
            )
        }),
        [i18n.language, t]
    );

    const res = useMemo(() => {
        return [
            IGA,
            EGR,
            AGA,
            EuropeGamingAwards,
            StarletAwards,
            SPiCEPhilippines
        ];
    }, [i18n.language, t]);
    return res;
};

export default useAward;
