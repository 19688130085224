import axios from 'axios';

const subjectMap = {
    'Game API': '1',
    'White Label Solution': '2',
    'Others': '3',
    '娱乐平台API介接': '1',
    '自有品牌建构': '2',
    '其他': '3'
} as {
    [key: string]: string;
};

export const handleSubmit = async (
    data: any,
    countryData: any
) => {
    const {
        content,
        name,
        interest,
        company,
        country,
        tel,
        email,
        msg,
        msg_id,
        subject,
        captcha
    } = data;

    const countryCallingCode = countryData.filter((item: any) => {
        if (item.name === country) return item;
    })[0].code;

    const formData = new FormData();
    formData.append('Contact[name]', name);
    formData.append('Contact[salutation]', name);
    formData.append('Contact[company]', company);
    formData.append('Contact[tel]', tel);
    formData.append('Contact[mobile]', tel);
    formData.append('Contact[email]', email);
    formData.append('Contact[country]', country);
    formData.append('Contact[msg]', msg);
    formData.append('Contact[msg_id]', msg_id);
    formData.append('Contact[interest]', interest);
    formData.append('Contact[subject]', subjectMap[subject]);
    formData.append('Contact[content]', content);
    formData.append('detail[0][title]', '国码 (必填)');
    formData.append('detail[0][value]', countryCallingCode);
    formData.append('captcha', captcha);

    axios({
        url: '/bbin/cn/Contact/submit',
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: formData
    })
        .then((res) => {
            console.log(res.data);
            if (res.data.send_sub_title === '验证码错误') window.alert(res.data.send_sub_title);
            else window.alert(res.data.message);
        })
        .catch((err) => {
            console.log(err.data);
        });
};
