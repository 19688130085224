import HallwayVideoPlane from 'components/models/HallwayVideoPlane/HallwayVideoPlane';
import useYearsGroup from './useYearsGroup';
import { memo } from 'react';
const YearsGroup = () => {
    const yearsGroupArr = useYearsGroup();
    return (
        <>
            {yearsGroupArr.map((item: any, index) => {
                return (
                    <HallwayVideoPlane
                        key={index}
                        {...item}
                    >
                        {item.content}
                    </HallwayVideoPlane>
                );
            })}
        </>
    );
};

export default memo(YearsGroup);
