const LogoComponent = ({
    canvasStatus,
    setCanvasStatus,
    hallwayToLoby
}: any) => {
    return (
        <img
            className="fixedButtonGroup-logo"
            src={'/leading/leadingPageAssets/indexPage/logo.png'}
            alt="logo"
            onClick={() => {
                if (hallwayToLoby) hallwayToLoby();
            }}
        />
    );
};

export default LogoComponent;
